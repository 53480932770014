import { useViewSubscription } from "@custom-hooks/billing";
import { SubscriptionStatusAlertProps } from "@data-types/billing-types";
import { FAILED_TO_VIEW_SUBSCRIPTION_ERROR_MESSAGE } from "@lib/billing/constants";
import { ContactDialog } from "@tw-components/organization-home/ContactDialog";
import { Alerts, Message, MessageType } from "@tw-components/ui/alerts";
import { GenericDialog } from "@tw-components/ui/dialog";
import { useState } from "react";

/**
 * Props for the SubscriptionStatusDialog component.
 *
 * @property {string} title - The title of the dialog.
 * @property {string} description - The description to be displayed in the alert.
 * @property {string} actionButtonText - The label for the primary action button.
 * @property {boolean} isOpen - Controls whether the dialog is open.
 * @property {() => void} onClose - Callback function invoked when the dialog is closed.
 */
type SubscriptionStatusDialogProps = SubscriptionStatusAlertProps & {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * Displays a dialog to show subscription status with options to view subscription
 * or contact support.
 *
 * @param {SubscriptionStatusDialogProps} props - Component props.
 *
 * @returns {JSX.Element} The rendered SubscriptionStatusDialog component.
 */
export function SubscriptionStatusDialog({
  title,
  description,
  actionButtonText,
  isOpen,
  onClose,
}: SubscriptionStatusDialogProps): JSX.Element {
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState<Message | undefined>(
    undefined
  );

  const {
    viewSubscription,
    isLoading: isLoadingViewSubscription,
    error: errorViewSubscription,
  } = useViewSubscription({
    errorCallback: () => {
      setAlertMessage(FAILED_TO_VIEW_SUBSCRIPTION_ERROR_MESSAGE);
    },
    stopGlobalError: true,
  });

  return (
    <>
      <GenericDialog
        onClose={onClose}
        open={isOpen}
        title={title}
        body={
          <Alerts
            messages={[{ type: MessageType.Error, title: description }]}
          />
        }
        submitButtonProps={{
          variant: "primary",
          label: actionButtonText,
          showLoader: isLoadingViewSubscription,
          className: "tw-w-52",
          onClick: viewSubscription,
        }}
        secondaryButtonProps={{
          text: "Contact us",
          onClick: () => {
            onClose();
            setShowContactDialog(true);
          },
        }}
        alertMessage={alertMessage}
        size="xl"
      />

      <ContactDialog
        isOpen={showContactDialog}
        onClose={() => setShowContactDialog(false)}
        error={errorViewSubscription}
        type={"billing"}
        source={"Subscription Status Dialog"}
      />
    </>
  );
}

import RocketIcon from "@layouts/svg-illustration/rocket-badge.svg";

/**
 * Returns a JSX element displaying an alert for the Pro Plan free trial.
 *
 * @returns A JSX element displaying the alert.
 */
export function ProPlanFreeTrialAlert(): JSX.Element {
  return (
    <div className="tw-min-h-12 tw-rounded tw-border tw-border-brandBlues-brand-light tw-bg-brandBlues-pale-light tw-px-2 tw-py-3 dark:tw-border-brandBlues-brand-dark dark:tw-bg-brandBlues-pale-dark">
      <div className="tw-flex tw-items-start tw-gap-x-1">
        <div className="tw-dark:tw-text-brandBlues-brandDark-dark tw-size-10 tw-shrink-0 tw-px-1 tw-text-brandBlues-brandDark-light">
          <RocketIcon />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="tw-text-14px-medium tw-text-brandBlues-brandDark-light dark:tw-text-brandBlues-brandDark-dark">
            Try SQLite Cloud Pro for free until March 1st
          </p>

          <p className="tw-mt-2 tw-text-12px-regular tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark">
            Upgrade your plan now to get all the benefits of SQLite Cloud Pro,
            free of charge. After March 1st, Pro plans will be renewed at
            $29/month.
          </p>
        </div>
      </div>
    </div>
  );
}

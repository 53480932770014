import { ClientError } from "@lib/client-side";
import { ContactDialog } from "@tw-components/organization-home/ContactDialog";
import { GenericDialog } from "@tw-components/ui/dialog";
import { useState } from "react";

/**
 * Props for the GenericErrorDialog component.
 *
 * @property {boolean} isOpen - Determines whether the dialog is currently open.
 * @property {() => void} onClose - Function to close the dialog.
 * @property {string} title - The title text displayed at the top of the dialog.
 * @property {React.ReactNode} body - The main content of the dialog.
 * @property {ClientError} [error] - An error object provided to the ContactDialog, to include error details when sending the user message.
 */
export type GenericErrorDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  body: React.ReactNode;
  error?: ClientError;
};

/**
 * A dialog component to display error messages with an option to contact support.
 *
 * @param {GenericErrorDialogProps} props - The props for the component.
 *
 * @returns {JSX.Element} The rendered GenericErrorDialog component.
 */
export function GenericErrorDialog({
  isOpen,
  onClose,
  title,
  body,
  error,
}: GenericErrorDialogProps): JSX.Element {
  const [showContactDialog, setShowContactDialog] = useState(false);

  return (
    <>
      <GenericDialog
        onClose={onClose}
        open={isOpen}
        title={title}
        body={body}
        submitButtonProps={{
          variant: "primary",
          label: "Close",
          className: "tw-w-52",
          onClick: () => {
            onClose();
          },
        }}
        secondaryButtonProps={{
          text: "Contact us",
          onClick: () => {
            onClose();
            setShowContactDialog(true);
          },
        }}
        size="2xl"
      />

      <ContactDialog
        isOpen={showContactDialog}
        onClose={() => setShowContactDialog(false)}
        error={error}
        type={"contact_us"}
        source={"Generic Error Dialog"}
      />
    </>
  );
}

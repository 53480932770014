//
// CreateProjectDialog.tsx
//

import {
  useCreateSubscription,
  useGetCurrentOrganizationId,
  useGetSubscriptionDetails,
  useUpdateSubscription,
} from "@custom-hooks/billing";
import {
  getEnvironmentOptions,
  useCreateFreeProject,
} from "@custom-hooks/projects";
import { useGetRegions } from "@custom-hooks/regions";
import {
  AddonActionNames,
  AddonLookupKey,
  AddonsFunctionality,
} from "@data-types/billing-types";
import { Region, Regions } from "@data-types/regions-types";
import {
  addons,
  FAILED_TO_LOAD_SUBSCRIPTION_DETAILS_ERROR_MESSAGE,
  SQLITE_CLOUD_ORGANIZATION_ID,
} from "@lib/billing/constants";
import { AddonPriceDetails } from "@tw-components/billing/AddonPriceDetails";
import { ProPlanFreeTrialAlert } from "@tw-components/billing/ProPlanFreeTrialAlert";
import { SubscriptionStatusDialog } from "@tw-components/billing/SubscriptionStatusDialog";
import { ContactDialog } from "@tw-components/organization-home/ContactDialog";
import { MessageType } from "@tw-components/ui/alerts";
import { GenericDialog } from "@tw-components/ui/dialog";
import { GenericInput } from "@tw-components/ui/input";
import {
  GenericListbox,
  ListboxLabel,
  ListboxOption,
} from "@tw-components/ui/listbox";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

/**
 * The props for the CreateProjectDialog component.
 *
 * @property {boolean} isOpen - Indicates whether the dialog is open.
 * @property {() => void} onClose - Callback function to close the dialog.
 */
type CreateProjectDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  projectType: AddonLookupKey;
};

/**
 * CreateProjectDialog component that displays a dialog for creating a new project.
 *
 * @param {CreateProjectDialogProps} props - The props for the CreateProjectDialog component.
 *
 * @returns {JSX.Element} The rendered `CreateProjectDialog` component.
 */
export function CreateProjectDialog({
  isOpen,
  onClose,
  projectType,
}: CreateProjectDialogProps): JSX.Element {
  const formikRef = useRef<FormikProps<{
    projectName: string;
    region: Region["region_id"];
    label: number;
  }> | null>(null);

  const environmentOptions = getEnvironmentOptions();

  const [regionOptions, setRegionOptions] = useState<Regions>([]);
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [showSubscriptionStatusDialog, setShowSubscriptionStatusDialog] =
    useState(false);
  const [furtherActionRequired, setFurtherActionRequired] = useState(false);
  const [invoiceHostedUrl, setInvoiceHostedUrl] = useState("");

  const organizationId = useGetCurrentOrganizationId();

  const {
    data: regions,
    showLoader: showLoaderRegions,
    isError: isErrorRegions,
  } = useGetRegions();

  const { updateSubscription, error: errorUpdateSubscription } =
    useUpdateSubscription({});
  const { createFreeProject, error: errorCreateFreeProject } =
    useCreateFreeProject({});
  const { createSubscription, error: errorCreateSubscription } =
    useCreateSubscription({});

  const { hasSubscription, subscriptionStatusAlertProps } =
    useGetSubscriptionDetails();

  const planDetails = addons[projectType].functionalityDetails;

  const details = [
    {
      name: AddonsFunctionality.CLUSTER_NODE_COUNT,
      value: planDetails[AddonsFunctionality.CLUSTER_NODE_COUNT]?.summary,
    },
    {
      name: AddonsFunctionality.STORAGE_IN_GB,
      value: planDetails[AddonsFunctionality.STORAGE_IN_GB]?.summary,
    },
    {
      name: `${AddonsFunctionality.VCPU} / ${AddonsFunctionality.RAM}`,
      value: `${planDetails[AddonsFunctionality.VCPU]?.summary} / ${planDetails[AddonsFunctionality.RAM]?.summary}`,
    },
    {
      name: AddonsFunctionality.RETENTION_IN_HOURS,
      value: planDetails[AddonsFunctionality.RETENTION_IN_HOURS]?.summary,
    },
    {
      name: AddonsFunctionality.SUPPORT,
      value: planDetails[AddonsFunctionality.SUPPORT]?.summary,
    },
  ];

  const validationSchema = Yup.object().shape({
    projectName: Yup.string()
      .required("Project name is required.")
      .matches(/^[A-Za-z0-9.-]*$/, {
        message: "Allowed characters are [a-z, A-Z, 0-9, . and -].",
        excludeEmptyString: true,
      })
      .test(
        "ends-with-alphanumeric",
        "The last character must be one of [a-z, A-Z, 0-9].",
        (value) => {
          if (!value) return true;
          const lastChar = value.slice(-1);
          return /^[A-Za-z0-9]$/.test(lastChar);
        }
      ),
  });

  const redirectToStripe = () => {
    window.open(invoiceHostedUrl, "_blank");
    onClose();
  };

  useEffect(() => {
    if (regions) {
      setRegionOptions(regions);
    }

    if (isErrorRegions) {
      formikRef?.current?.setFieldError(
        "region",
        "Error: Failed to retrieve available regions."
      );
    }
  }, [regions, isErrorRegions]);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          projectName: "",
          region: process.env
            .NEXT_PUBLIC_USE_DEFAULT_REGION_K8S as Region["region_id"],
          label: environmentOptions[0].value,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
          setStatus(undefined);
          setSubmitting(true);

          if (UPGRADING_INFRA && UPGRADING_INFRA.toLowerCase() === "true") {
            setStatus({
              type: MessageType.Error,
              title: "Infrastructure Upgrade in Progress",
              description:
                "We're upgrading our infrastructure to serve you better! Creating a new project is temporarily disabled. Please try again later.",
            });

            return;
          }

          if (organizationId === SQLITE_CLOUD_ORGANIZATION_ID) {
            const createFreeProjectResult = await createFreeProject({
              projectName: values.projectName,
              region: values.region,
              label: values.label,
            });

            if (createFreeProjectResult.success) {
              onClose();
              resetForm();
            } else {
              setStatus({
                type: MessageType.Error,
                title: "Unable to create project",
                description:
                  "An error occurred while creating your project. Please try again later. If the issue persists, contact us for assistance.",
              });
            }
          } else {
            switch (projectType) {
              case AddonLookupKey.SANDBOX_PROJECT:
                const createFreeProjectResult = await createFreeProject({
                  projectName: values.projectName,
                  region: values.region,
                  label: values.label,
                });

                if (createFreeProjectResult.success) {
                  onClose();
                  resetForm();
                } else {
                  setStatus({
                    type: MessageType.Error,
                    title: "Unable to create project",
                    description:
                      "An error occurred while creating your sandbox project. Please try again later. If the issue persists, contact us for assistance.",
                  });
                }

                break;
              case AddonLookupKey.PRO_PROJECT:
              case AddonLookupKey.SCALE_PROJECT:
                if (hasSubscription) {
                  const updateSubscriptionResult = await updateSubscription({
                    newAddon: {
                      addon: AddonLookupKey.PRO_PROJECT,
                      quantity: 1,
                      action: {
                        name: AddonActionNames.CREATE,
                        data: {
                          name: values.projectName,
                          region: values.region,
                          env: values.label,
                        },
                      },
                    },
                  });

                  if (updateSubscriptionResult.success) {
                    switch (updateSubscriptionResult.data.value.status) {
                      case "pending":
                        setInvoiceHostedUrl(
                          updateSubscriptionResult.data.value.redirectUrl
                        );
                        setFurtherActionRequired(true);

                        setStatus({
                          type: MessageType.Error,
                          title: "Further action is required on Stripe",
                        });

                        break;
                      case "completed":
                        onClose();
                        resetForm();
                        break;
                    }
                  } else {
                    setStatus({
                      type: MessageType.Error,
                      title: "Unable to purchase project",
                      description:
                        "We couldn't update your subscription to include this project. Please try again later. If the issue persists, contact us for assistance.",
                    });
                  }
                } else {
                  const createSubscriptionResult = await createSubscription({
                    newAddon: {
                      addon: AddonLookupKey.PRO_PROJECT,
                      quantity: 1,
                      action: {
                        name: AddonActionNames.CREATE,
                        data: {
                          name: values.projectName,
                          region: values.region,
                          env: values.label,
                        },
                      },
                    },
                  });

                  if (createSubscriptionResult.success) {
                    onClose();
                    resetForm();
                  } else {
                    setStatus({
                      type: MessageType.Error,
                      title: "Unable to purchase project",
                      description:
                        "We couldn't create your subscription to include this project. Please try again later. If the issue persists, contact us for assistance.",
                    });
                  }
                }
                break;
              default:
                setStatus(FAILED_TO_LOAD_SUBSCRIPTION_DETAILS_ERROR_MESSAGE);
                break;
            }
          }

          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          isValid,
          isSubmitting,
          status,
          setStatus,
          handleSubmit,
          setFieldValue,
          errors,
          resetForm,
        }) => (
          <GenericDialog
            title="Create a new project"
            body={
              <Form className="tw-flex tw-flex-col tw-gap-4">
                <div className="tw-text-16px-semiBold tw-text-text-title-light dark:tw-text-text-title-dark">
                  Configuration
                </div>

                <GenericInput
                  sizeVar="small"
                  label="Project name"
                  placeholder="my-project"
                  name="projectName"
                  value={values.projectName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setStatus(undefined);
                  }}
                  onBlur={handleBlur}
                  errors={errors}
                  disabled={isSubmitting || furtherActionRequired}
                />

                <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2">
                  <GenericListbox
                    style="default"
                    size="small"
                    label="Region"
                    placeholder="Loading regions..."
                    name="region"
                    value={isErrorRegions ? "" : values.region}
                    onChange={(value: Region["region_id"]) => {
                      setFieldValue("region", value);
                      setStatus(undefined);
                    }}
                    disabled={
                      showLoaderRegions ||
                      isErrorRegions ||
                      isSubmitting ||
                      furtherActionRequired
                    }
                    showLoader={showLoaderRegions}
                    errors={errors}
                  >
                    {regionOptions.map((region) => (
                      <ListboxOption
                        value={region.region_id}
                        key={region.region_id}
                      >
                        <ListboxLabel>{region.description}</ListboxLabel>
                      </ListboxOption>
                    ))}
                  </GenericListbox>

                  <GenericListbox
                    style="default"
                    size="small"
                    label="Label"
                    name="label"
                    value={values.label}
                    onChange={(value: number) => {
                      setFieldValue("label", value);
                      setStatus(undefined);
                    }}
                    disabled={isSubmitting || furtherActionRequired}
                  >
                    {environmentOptions.map((environment) => (
                      <ListboxOption
                        value={environment.value}
                        key={environment.value}
                      >
                        <ListboxLabel>{environment.label}</ListboxLabel>
                      </ListboxOption>
                    ))}
                  </GenericListbox>
                </div>

                <div className="tw-flex tw-border-t tw-border-lightestGrey" />

                <div className="tw-text-16px-semiBold tw-text-text-title-light dark:tw-text-text-title-dark">
                  Details
                </div>

                <div className="tw-flex tw-flex-col tw-gap-2">
                  {details.map((detail) => (
                    <div
                      className="tw-flex tw-flex-row tw-justify-between tw-gap-2 tw-py-1.5"
                      key={detail.name}
                    >
                      <div className="tw-text-14px-regular tw-text-mediumblue">
                        {detail.name}
                      </div>

                      <div className="tw-text-14px-regular tw-text-mediumblue">
                        {detail.value ?? "None"}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="tw-flex tw-border-t tw-border-lightestGrey" />

                <div className="tw-flex tw-flex-row tw-justify-between tw-gap-2 tw-py-1.5">
                  <div className="tw-text-16px-semiBold tw-text-text-title-light dark:tw-text-text-title-dark">
                    Cost
                  </div>

                  <AddonPriceDetails
                    addonKey={projectType}
                    className="tw-text-16px-semiBold tw-text-text-title-light dark:tw-text-text-title-dark"
                  />
                </div>

                {projectType === AddonLookupKey.PRO_PROJECT && (
                  <ProPlanFreeTrialAlert />
                )}
              </Form>
            }
            alertMessage={status}
            secondaryButtonProps={{
              text: "Need more? Contact us",
              onClick: () => {
                onClose();
                setShowContactDialog(true);
              },
            }}
            submitButtonProps={{
              variant: "primary",
              size: "medium",
              label: furtherActionRequired ? "Continue to Stripe" : "Create",
              onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                if (furtherActionRequired) {
                  redirectToStripe();
                } else {
                  handleSubmit();
                }
              },
              className: "tw-w-[6.625rem]",
              disabled: !isValid,
              showLoader: isSubmitting,
            }}
            onClose={onClose}
            afterLeave={resetForm}
            beforeEnter={() => {
              if (subscriptionStatusAlertProps) {
                switch (projectType) {
                  case AddonLookupKey.PRO_PROJECT:
                  case AddonLookupKey.SCALE_PROJECT:
                    onClose();
                    setShowSubscriptionStatusDialog(true);
                    break;
                  case AddonLookupKey.SANDBOX_PROJECT:
                  default:
                    break;
                }
              }
            }}
            open={isOpen}
            size="lg"
            buttonLayoutType="column"
          />
        )}
      </Formik>

      <ContactDialog
        isOpen={showContactDialog}
        onClose={() => setShowContactDialog(false)}
        error={
          errorCreateFreeProject ||
          errorUpdateSubscription ||
          errorCreateSubscription
        }
        type={"billing"}
        source={"Create Project Dialog"}
      />

      {subscriptionStatusAlertProps && (
        <SubscriptionStatusDialog
          {...subscriptionStatusAlertProps}
          isOpen={showSubscriptionStatusDialog}
          onClose={() => setShowSubscriptionStatusDialog(false)}
        />
      )}
    </>
  );
}
